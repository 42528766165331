import * as Styled from "./style";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Signature from "../../Atoms/Signature/Signature";
import { useSelector } from "react-redux";
import { AccountDashboardData } from "./data";
import * as dayjs from "dayjs";
import { MdClose } from "react-icons/md";
import { message, Tabs } from "antd";
import HttpApis from "../../../services/HttpApis";
import html2canvas from "html2canvas";

export const AccountDashboard = ({
  isInput = false,
  CorporateUser = false,
  declareData,
  setDeclareData,
  errors,
  setSign = () => null,
  formData,
  isInvitedDeclaration,
  item,
  isFormData,
  isName,
  isStaffDetails = false,
  isLicense = true,
  index,
  fetchLicensesList,
  declarationStatus,
  isTraineeData,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const signatureRef = useRef();
  const [tabIndex, setTabIndex] = useState(1);
  const [signFile, setSignFile] = useState();
  const [finalSign, setFinalSign] = useState();
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedImageError, setSelectedImageError] = useState(false);
  const signImageRef = useRef();
  const dragCounter = useRef(0);
  const [dragging, setDragging] = useState(false);
  const [text, setText] = useState("");
  const textRef = useRef(null);

  const tabChange = (key) => {
    setTabIndex(key);
    setSignFile(null);
    setText("");
    setSelectedImage("");
    setSelectedImageError(false);
    localStorage.setItem("sign", false);
    signatureRef?.current?.clear();
  };

  const handleModalOk = async () => {
    let response;
    if (
      userDetail?.type === "Finance Account" ||
      userDetail?.type === "Operations"
    ) {
      response = await HttpApis.licensesSignature({ sign: signFile });
      if (response?.status === 200) {
        message.success(response?.data?.message);
        fetchLicensesList();
      }
    }
    setFinalSign(signFile);
    setSign(signFile);
    setModalOpen(false);
    return response;
  };

  const onFileChange = async (file) => {
    setSelectedImage(file);
    if (file && file?.size < 5 * 1024 * 1024) {
      setSelectedImageError(false);
      localStorage.setItem("sign", true);
      let base64String;
      const reader = new FileReader();
      reader.onloadend = () => {
        base64String = reader.result;
        setSignFile(base64String);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedImageError(true);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current--;
    if (dragCounter.current === 0) {
      setDragging(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current = 0;
    setDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      onFileChange(e.dataTransfer.files[0]);
      e.dataTransfer.clearData();
    }
  };

  const handleSubmit = async () => {
    localStorage.setItem("sign", true);
    const data = signatureRef?.current?.toDataURL();
    setSignFile(data);
  };

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setDeclareData((prev) => ({ ...prev, [name]: newValue }));
  };
  const userDetail = useSelector((state) => state?.authReducer?.user);
  const firstName = item?.isStaff
    ? item?.staffDetails?.firstName
      ? item?.staffDetails?.firstName
      : ""
    : item?.user?.firstName
    ? item?.user?.firstName
    : "";
  const lastName = item?.isStaff
    ? item?.staffDetails?.lastName
      ? item?.staffDetails?.lastName
      : ""
    : item?.user?.lastName
    ? item?.user?.lastName
    : "";

  const user_firstname = userDetail?.firstName ? userDetail?.firstName : "";
  const user_lastname = userDetail?.lastName ? userDetail?.lastName : "";

  const declarationFormfirstName = formData?.firstName
    ? formData?.firstName
    : "";
  const declarationFormlastName = formData?.lastName ? formData?.lastName : "";
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();

  today = dd + "/" + mm + "/" + yyyy;

  const toDataUrl = (url, callback) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  };

  const handleGenerateImage = async () => {
    localStorage.setItem("sign", true);
    const element = textRef.current;
    html2canvas(element).then((canvas) => {
      const screenshot = canvas.toDataURL("image/png");
      setSignFile(screenshot);
    });
  };

  const handleTextInput = (e) => {
    setText(e.target.value);
    debouncedGenerateImage();
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func();
      }, delay);
    };
  };

  const debouncedGenerateImage = useMemo(
    () => debounce(handleGenerateImage, 500),
    []
  );

  useEffect(() => {
    if (item?.sign && signImageRef?.current?.src.indexOf("base64,") === -1) {
      toDataUrl(signImageRef.current.src, (imgBase64) => {
        signImageRef.current.src = imgBase64;
      });
    }
  }, [item?.sign]);

  const signature = item?.isStaff ? item?.staffDetails?.sign : item?.sign;
  const user_email = () => {
    let email;
    if (isStaffDetails) {
      email = userDetail?.email ? userDetail?.email : "";
    } else {
      if (item?.isStaff) {
        email = item?.staffDetails?.email ? item?.staffDetails?.email : "";
      } else {
        email = item?.user?.email ? item?.user?.email : "";
      }
    }
    return email;
  };
  const staff_signature = item?.isStaff
    ? item?.staffDetails?.sign
    : formData?.sign;
  const items = [
    {
      key: "1",
      label: "Draw",
      children: (
        <Signature
          signatureRef={signatureRef}
          handleSubmit={handleSubmit}
          userDetail={userDetail}
          fetchLicensesList={fetchLicensesList}
        />
      ),
    },
    {
      key: "2",
      label: "Image",
      children: (
        <>
          <Styled.SignUploadInputWrapper
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            dragging={dragging}
          >
            <div>
              <label for="files" className="uploadImageText">
                Select Image
              </label>
              <input
                id="files"
                style={{ display: "none" }}
                type="file"
                onChange={(e) => onFileChange(e.target.files[0])}
              />

              {selectedImage?.name && <p>{selectedImage?.name}</p>}
            </div>
          </Styled.SignUploadInputWrapper>
          <Styled.ChildText>
            <div className="imageError">
              {!selectedImageError ? "" : "Image size should be less than 5MB"}
            </div>
            {dragging ? "Drop the image here..." : "Select or Drag Image"}
          </Styled.ChildText>
        </>
      ),
    },
    {
      key: "3",
      label: "Type",
      children: (
        <>
          <Styled.SignText>
            <Styled.TextInput
              type="text"
              value={text}
              onChange={(e) => handleTextInput(e)}
              ref={textRef}
            />
          </Styled.SignText>
          <Styled.ChildText>Sign Here</Styled.ChildText>
        </>
      ),
    },
  ];
  return (
    <div>
      <div>
        <Styled.Container>
          <Styled.ItText>{AccountDashboardData.It}</Styled.ItText>
          <Styled.ListText>
            <div className="rulesText">
              {AccountDashboardData.IN}
              <span className="backgroundtext">
                {AccountDashboardData.Background}
              </span>
              {AccountDashboardData.shall}
            </div>
            <ol>
              <li>{AccountDashboardData.establish}</li>
              <li>{AccountDashboardData.cover}</li>
              <li>{AccountDashboardData.criminal}</li>
            </ol>
          </Styled.ListText>
        </Styled.Container>
        <Styled.BackgroundContainer>
          <Styled.BoxSize>
            {CorporateUser ? (
              <Styled.LearnerName>
                <span className="confirm">
                  I can confirm that <b>ALL</b> of
                </span>
                &nbsp;&nbsp;
                <Styled.InputText
                  type="text"
                  name="name"
                  defaultValue={
                    userDetail?.companyName ? userDetail?.companyName : ""
                  }
                  placeholder={AccountDashboardData.NAME_OF_ACCOUNT}
                  required
                />
                <span className="confirm">
                  {AccountDashboardData.undertaking}{" "}
                </span>{" "}
              </Styled.LearnerName>
            ) : (
              <Styled.LearnerName>
                <span className="confirm">{AccountDashboardData.confirm}</span>
                {isInvitedDeclaration ? (
                  isFormData ? (
                    <Styled.InviteInputText>
                      {declarationFormfirstName + " " + declarationFormlastName}
                    </Styled.InviteInputText>
                  ) : (
                    <Styled.InviteInputText>
                      {isName ? item?.name : item?.traineeName}
                    </Styled.InviteInputText>
                  )
                ) : (
                  <Styled.InputText
                    type="text"
                    name="name"
                    defaultValue={user_firstname + " " + user_lastname}
                    placeholder={AccountDashboardData.NAME_OF_TRAINEE}
                    required
                  />
                )}
                <span className="confirm">
                  {AccountDashboardData.isEmployed}
                </span>
                {isInvitedDeclaration ? (
                  isFormData ? (
                    <Styled.InputTextInviteRoll>
                      {formData?.role}
                    </Styled.InputTextInviteRoll>
                  ) : (
                    <Styled.InputTextInviteRoll>
                      {item?.jobrole}
                    </Styled.InputTextInviteRoll>
                  )
                ) : (
                  <Styled.InputTextRoll
                    type="text"
                    name="role"
                    value={declareData?.role}
                    placeholder={AccountDashboardData.ROLE_NAME}
                    onChange={handleChange}
                    required
                  />
                )}
                {errors?.name && <Styled.Error>{errors?.role}</Styled.Error>}
                <span className="confirm">
                  {AccountDashboardData.Successfully}
                </span>
              </Styled.LearnerName>
            )}

            <Styled.BackgroundCheck>
              <span className="check">{AccountDashboardData.BACKGROUND} </span>
              {AccountDashboardData.access}
            </Styled.BackgroundCheck>
            <Styled.PleaseText>
              <div className="please">{AccountDashboardData.Pleasetick}</div>
              {isInvitedDeclaration ? (
                <input
                  type="checkbox"
                  className="inputstyled"
                  name="backgroundCeck"
                  checked
                  required
                />
              ) : (
                <input
                  type="checkbox"
                  className="inputstyled"
                  name="backgroundCeck"
                  onChange={handleChange}
                  required
                />
              )}
              {errors?.backgroundCeck && (
                <Styled.Error>{errors?.backgroundCeck}</Styled.Error>
              )}
            </Styled.PleaseText>
            <span className="airside">{AccountDashboardData.And}</span>
            <div className="textContainer">
              {AccountDashboardData.Has}
              <span className="airside">{AccountDashboardData.AIRSIDE}</span>
              {AccountDashboardData.assigned}
              <span className="airside">{AccountDashboardData.Security}</span>
              {AccountDashboardData.required}
            </div>
            <Styled.PleaseText>
              <div className="please">{AccountDashboardData.Pleasetick}</div>
              {isInvitedDeclaration ? (
                <input
                  type="checkbox"
                  className="inputstyled"
                  name="backgroundCeck"
                  checked
                  required
                />
              ) : (
                <input
                  type="checkbox"
                  className="inputstyled"
                  name="cpsra"
                  onChange={handleChange}
                  required
                />
              )}
              {errors?.cpsra && <Styled.Error>{errors?.cpsra}</Styled.Error>}
            </Styled.PleaseText>
          </Styled.BoxSize>
        </Styled.BackgroundContainer>
        <Styled.Container>
          <Styled.MALPRACTICE>
            <div className="malpractice">
              {AccountDashboardData.MALPRACTICE}
            </div>
            <div className="malpracticeText">
              {AccountDashboardData.deliberate}
            </div>
          </Styled.MALPRACTICE>
          <Styled.AppropriateContainer>
            <div className="appropriate">
              {AccountDashboardData.APPROPRIATEAUTHORITYACTION}
            </div>
            <div className="dftText">
              {AccountDashboardData.The}
              <span className="appropriate">{AccountDashboardData.DfT}</span>
              {AccountDashboardData.willseek}
            </div>
            <div className="purchasing ">
              {AccountDashboardData.purchasing}{" "}
              <span className="appropriate">{AccountDashboardData.DfT}</span>{" "}
              {AccountDashboardData.result}{" "}
              <span className="appropriate">{AccountDashboardData.DfT}</span>
              {AccountDashboardData.contravention}{" "}
              <span className="appropriate">
                {AccountDashboardData.National}
              </span>
              {AccountDashboardData.associatedsyllabuses}
            </div>
          </Styled.AppropriateContainer>
        </Styled.Container>
        <Styled.BackgroundContainer1>
          <Styled.BoxContainer>
            <Styled.CompanyName>
              <div className="nameContainer">
                <div className="company">
                  {AccountDashboardData.CompanyName}
                </div>
                <div className="companytext">
                  {isStaffDetails
                    ? userDetail?.companyName
                      ? userDetail?.companyName
                      : ""
                    : item?.user?.companyName
                    ? item?.user?.companyName
                    : ""}
                </div>
              </div>
              <div className="nameContainer">
                <div className="company">
                  {AccountDashboardData.CompanyAddress}
                </div>
                <div className="companytext2">
                  {isStaffDetails
                    ? userDetail?.addressLine1
                      ? userDetail?.addressLine1
                      : ""
                    : item?.user?.addressLine1
                    ? item?.user?.addressLine1
                    : ""}
                </div>
                <div className="companytext2">
                  {isStaffDetails
                    ? userDetail?.addressLine2
                      ? userDetail?.addressLine2
                      : ""
                    : item?.user?.addressLine2
                    ? item?.user?.addressLine2
                    : ""}
                </div>
                <div className="companytext1">
                  {isStaffDetails
                    ? userDetail?.city
                      ? userDetail?.city
                      : ""
                    : item?.user?.city
                    ? item?.user?.city
                    : ""}
                  ,
                  {isStaffDetails
                    ? userDetail?.postcode
                      ? userDetail?.postcode
                      : ""
                    : item?.user?.postcode
                    ? item?.user?.postcode
                    : ""}
                </div>
              </div>
              <div className="nameContainer">
                <div className="company">{AccountDashboardData.YourName}</div>
                <div className="companytext2">
                  {isStaffDetails
                    ? user_firstname + " " + user_lastname
                    : firstName + " " + lastName}
                </div>
              </div>
            </Styled.CompanyName>

            <Styled.CompanyRegNumber>
              <div className="nameContainer">
                <div className="company">
                  {AccountDashboardData.CompanyRegNumber}
                </div>
                <div className="textContainer">
                  {isStaffDetails
                    ? userDetail?.companyRegNo
                      ? userDetail?.companyRegNo
                      : ""
                    : item?.user?.companyRegNo
                    ? item?.user?.companyRegNo
                    : ""}
                </div>
              </div>
              <div className="nameContainer">
                <div className="company">
                  {AccountDashboardData.LandlineNumber}
                </div>
                <div className="textContainer1">
                  {isStaffDetails
                    ? userDetail?.landingNo
                      ? userDetail?.landingNo
                      : ""
                    : item?.user?.landingNo
                    ? item?.user?.landingNo
                    : ""}
                </div>
              </div>
              <div className="nameContainer">
                <div className="company">
                  {AccountDashboardData.YourMobileNumber}
                </div>
                <div className="textContainer2">
                  {isStaffDetails
                    ? userDetail?.mobile
                      ? userDetail?.mobile
                      : ""
                    : item?.user?.mobile
                    ? item?.user?.mobile
                    : ""}
                </div>
              </div>
            </Styled.CompanyRegNumber>
            <Styled.YourEmail>
              <div className="nameContainer">
                <div className="company">{AccountDashboardData.YourEmail}</div>
                <div className="textContainer">{user_email()}</div>
              </div>
            </Styled.YourEmail>
          </Styled.BoxContainer>
          <Styled.JobRole>
            {CorporateUser ? (
              <>
                <Styled.Label>
                  {AccountDashboardData.jobTitle}
                  <span className="star">{AccountDashboardData.star}</span>
                </Styled.Label>
                <br />
                <Styled.Input
                  type="text"
                  name="jobrole"
                  onChange={handleChange}
                  value={declareData?.jobrole}
                  required
                />
              </>
            ) : (
              <>
                {isInvitedDeclaration ? (
                  <Styled.JobRole>
                    <Styled.Label>{AccountDashboardData.jobTitle}</Styled.Label>
                    <br />
                    <div className="textContainer2">
                      {isTraineeData
                        ? item?.jobrole
                          ? item?.jobrole
                          : ""
                        : declarationStatus?.jobrole
                        ? declarationStatus?.jobrole
                        : ""}
                    </div>
                  </Styled.JobRole>
                ) : (
                  <>
                    <Styled.Label>
                      <>
                        {AccountDashboardData.job}
                        <span className="star">
                          {AccountDashboardData.star}
                        </span>
                      </>
                    </Styled.Label>
                    <br />
                    <Styled.Input
                      type="text"
                      name="jobrole"
                      defaultValue={declareData?.role}
                      required
                    />
                  </>
                )}
              </>
            )}
            {declareData?.role === "" && errors?.role && (
              <Styled.Error>{errors?.role}</Styled.Error>
            )}
          </Styled.JobRole>
        </Styled.BackgroundContainer1>
        {isInput && (
          <Styled.SoleTrader>
            <div className="trader">
              If you are a sole trader and your background declaration has been
              self-authorised, we will require confirmation of the dates of the
              period of self-employment from independent accountants, solicitors
              or from the relevant government department or agency (on their
              company letterheaded paper), or the DfT. Please note that in order
              for your references to conform to Chapter 11 guidelines, all
              references must be dated, include a signature, and all
              confirmation of dates require a ‘From and To’ date which must be
              in DD/MM/YYYY format.
            </div>
          </Styled.SoleTrader>
        )}

        <Styled.DateContainer>
          <div>
            <div className="date">{AccountDashboardData.Date}</div>
            {isInvitedDeclaration ? (
              isFormData ? (
                <Styled.Date className="fulldate">{today}</Styled.Date>
              ) : (
                <Styled.Date className="fulldate">
                  {dayjs(item?.createdAt).format("DD-MM-YYYY")}
                </Styled.Date>
              )
            ) : (
              <Styled.Date className="fulldate">{today}</Styled.Date>
            )}
          </div>
          {(isInvitedDeclaration && userDetail?.type === "Corporate User") ||
          isLicense ||
          index > 0 ||
          item?.staffDetails?.sign ? (
            isFormData ? (
              <Styled.SignImage>
                <img
                  className="sign"
                  src={`${process.env.REACT_APP_URL}api/files/${staff_signature}`}
                  alt="signature"
                />
              </Styled.SignImage>
            ) : (
              <Styled.SignImage>
                <img
                  ref={signImageRef}
                  className="sign"
                  src={`${process.env.REACT_APP_URL}api/files/${signature}`}
                  alt="signature"
                />
              </Styled.SignImage>
            )
          ) : (
            <Styled.SignWrapper>
              <button className="add" onClick={() => setModalOpen(true)}>
                Add Signature
              </button>
              <Styled.SignedImage>
                {finalSign && (
                  <img
                    src={finalSign}
                    alt="signature"
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                )}
              </Styled.SignedImage>
              {!finalSign && errors?.sign && (
                <Styled.Error>{errors?.sign}</Styled.Error>
              )}
            </Styled.SignWrapper>
          )}
        </Styled.DateContainer>
      </div>
      {modalOpen && (
        <div>
          <Styled.ModalContainer
            style={{
              top: 40,
            }}
            width={700}
            closeIcon={false}
            open={modalOpen}
            onOk={handleModalOk}
            okText="Done"
            okButtonProps={{
              disabled: selectedImageError,
            }}
            onCancel={() => setModalOpen(false)}
          >
            <Styled.ModalHeader>
              <Styled.ModalTitle>Add Signature</Styled.ModalTitle>
              <Styled.Icon onClick={() => setModalOpen(false)}>
                <MdClose className="closeIcon" />
              </Styled.Icon>
            </Styled.ModalHeader>
            <Tabs
              defaultActiveKey={tabIndex}
              items={items}
              onChange={tabChange}
            />
          </Styled.ModalContainer>
        </div>
      )}
    </div>
  );
};
