import * as Styled from "./style";
import MultiStepProgressBar from "../../components/Atoms/MultiStepProgressBar/MultiStepProgressBar";
import React from "react";
import logAvtav from "../../assets/Svg/logAvtav.svg";
import useDeclarationHook from "../../hooks/DeclarationDataHook";
import useStepper from "../../hooks/StepperHook";
import { constant } from "../../constants/Constants";
import { browseData } from "./BrowseData";

const BrowseDeclaration = () => {
  const {
    declarationConfirm,
    current,
    prev,
    next,
    declareData,
    userConfirmation,
    errors,
    setErrors,
    emailData
  } = useStepper();
  const { handleData, handleConfirmation } = useDeclarationHook();
  return (
    <>
      <Styled.Contents>
        <Styled.DeclareContainer>
          <img src={logAvtav} alt="avtav" />
          <span className="declare">
            {browseData.declare}
            <br />
            <span className="aviation">{browseData.aviation}</span>
          </span>
        </Styled.DeclareContainer>
        <MultiStepProgressBar
          steps={declarationConfirm}
          nextButton={constant.Next}
          previousButton={constant.Previous}
          buttonHide={true}
          status="browse"
          current={current}
          next={next}
          prev={prev}
          handleData={handleData}
          declareData={declareData}
          handleConfirmation={handleConfirmation}
          userConfirmation={userConfirmation}
          errors={errors}
          setErrors={setErrors}
          emailData={emailData}
        />
      </Styled.Contents>
    </>
  );
};

export default BrowseDeclaration;
