import "react-loading-skeleton/dist/skeleton.css";
import "./BraintreeStyle.css";
import * as Styled from "./style";
import Button from "../../components/Atoms/Button/button";
import ButtonWrapper from "./ButtonWrapper";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import maestro from "../../assets/Svg/maestro.svg";
import masterCard from "../../assets/Svg/masterCard.svg";
import paypal from "../../assets/Svg/paypal.svg";
import useBillingHook from "../../hooks/BillingHook";
import useCheckoutHook from "../../hooks/CheckoutHook";
import useCommonHook from "../../hooks/CommonHook";
import visa from "../../assets/Svg/Visa.svg";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Checkbox } from "antd";
import { BsCurrencyPound } from "react-icons/bs";
import { MdOutlineCreditCard } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Checkout } from "../../constants/Constants";
import { Routes } from "../../routes/routes";

export const CheckoutPage = ({ isPayment = false }) => {
  const [state, setState] = useState();
  const [cities, setCities] = useState();
  const [disabledRadio, setDisabledRadio] = useState(false);
  const cartList = useSelector((state) => state?.cartReducer?.cart?.data);
  const checkoutReferer = useSelector(
    (state) => state?.commonReducer?.checkoutReferer
  );

  const {
    handleCheckoutData,
    checkoutData,
    setCheckoutData,
    errors,
    showBraintreeDropIn,
    setShowBraintreeDropIn,
    braintreeToken,
    checkoutFormDataAction,
    selectedRadio,
    setSelectedRadio,
  } = useCheckoutHook();
  const navigate = useNavigate();
  const { getBillingData } = useBillingHook();

  const handleRadioChange = (item) => {
    setSelectedRadio(item);
    setCheckoutData(item);
  };
  const { fetchCountry, fetchState, fetchCities } = useCommonHook();
  const billingAddress = useSelector(
    (state) => state?.billingReducer?.billingList?.data
  );

  const countryList = useSelector((state) => state?.commonReducer?.country);
  useEffect(() => {
    if (countryList && countryList?.length === 0) {
      fetchCountry();
    }
  }, [countryList]);
  useEffect(() => {
    getBillingData();
  }, []);
  const handleBuyCourse = async () => {
    const resp = await handleCheckoutData(checkoutData);
    if (resp && resp?.status) {
      if (checkoutReferer === "payment-message") {
        navigate(Routes?.paymentMessage);
        localStorage.removeItem("checkoutForm");
      } else if (checkoutReferer === "payment-sole-trader") {
        navigate(Routes?.paymentSoleTrader);
        localStorage.removeItem("checkoutForm");
      } else {
        navigate(Routes?.orderConfirmation);
        localStorage.removeItem("checkoutForm");
      }
    }
    return resp;
  };

  useEffect(() => {
    if (
      checkoutData?.firstName != "" &&
      checkoutData?.lastName != "" &&
      checkoutData?.email != "" &&
      checkoutData?.phone != "" &&
      checkoutData?.companyName != "" &&
      checkoutData?.address != "" &&
      checkoutData?.zipCode != "" &&
      checkoutData?.country != "" &&
      checkoutData?.state != "" &&
      checkoutData?.city != ""
    ) {
      setDisabledRadio(false);
    } else {
      setDisabledRadio(true);
    }
  }, [checkoutData]);
  const handleValue = async (e) => {
    const _checkoutData = {
      ...checkoutData,
      [e?.target?.id]: e?.target?.value,
    };
    setCheckoutData(_checkoutData);
    if (e?.target?.id === "country") {
      const countryId = e.target.value;
      if (countryId) {
        const stateResponse = await fetchState(countryId);
        setState(stateResponse);
      }
    }
    if (e?.target?.id === "state") {
      const stateId = e.target.value;
      if (stateId) {
        const citiesRespose = await fetchCities(stateId);
        setCities(citiesRespose);
      }
    }
    checkoutFormDataAction(_checkoutData);
  };
  let cartTotal = 0;
  cartList &&
    cartList?.forEach((item) => {
      cartTotal += parseInt(item?.price);
    });

  const [paymentGatewayType, setPaymentGatewayType] = useState("card");
  const checkPaymentType = (e) => {
    setPaymentGatewayType(e.target.value);
    const _checkoutData = {
      ...checkoutData,
      [e?.target?.id]: e?.target?.value,
    };
    setCheckoutData(_checkoutData);
    checkoutFormDataAction(_checkoutData);
  };
  const handleSaveBilling = (e) => {
    const _checkoutData = {
      ...checkoutData,
      isBillingAddress: e?.target?.checked ? 1 : 0,
    };
    setCheckoutData(_checkoutData);
  };

  return (
    <>
      {isPayment && <Styled.NextLineDeclare />}
      <Styled.MainContainer>
        <Styled.FirstConatiner>
          <Styled.Container>
            <form>
              <Styled.FormData>
                <div className="banner">
                  <div className="order">{Checkout.Order_Details}</div>
                  <Styled.OrderDetail>
                    <Styled.Course>
                      <div className="course">{Checkout.name}</div>
                    </Styled.Course>
                    <Styled.Qty>
                      <div className="qty">{Checkout.q}</div>
                    </Styled.Qty>
                    <Styled.Price>
                      <div className="price">{Checkout.price}</div>
                    </Styled.Price>
                  </Styled.OrderDetail>
                  {cartList?.map((item) => {
                    return (
                      <Styled.OrderDetail>
                        <Styled.Course>
                          <div className="cartText">{item?.Course?.title}</div>
                        </Styled.Course>
                        <Styled.Qty>
                          <div className="cartQty">{item?.qty}</div>
                        </Styled.Qty>
                        <Styled.Price>
                          <div className="cartPrice">
                            £{item?.Course?.price}
                          </div>
                        </Styled.Price>
                      </Styled.OrderDetail>
                    );
                  })}
                  <Styled.Line />
                  {billingAddress?.length > 0 ? (
                    <Styled.Billing>
                      <div className="select">{Checkout.selectBill}</div>
                      <div className="radioBox">
                        <div className="add">
                          {billingAddress?.map((item) => {
                            return (
                              <>
                                <span className="addData">
                                  <input
                                    type="radio"
                                    value="address"
                                    name="address"
                                    onChange={() => handleRadioChange(item)}
                                    className="radioBtn"
                                  />
                                  <label className="address">
                                    {item?.address}, {item?.City?.name},{" "}
                                    {item?.State?.name},{item?.Country?.name},{" "}
                                    {item?.zipCode}
                                  </label>
                                </span>
                                <br />
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </Styled.Billing>
                  ) : null}
                  <div className="billing">{Checkout.Billing_Details}</div>
                </div>
              </Styled.FormData>

              <Styled.FormDataContainer>
                <Styled.FormData className="formData">
                  <label className="label">{Checkout.First_name}</label>
                  <input
                    className="input"
                    type="text"
                    id="firstName"
                    onChange={handleValue}
                    value={checkoutData?.firstName}
                  />
                  {errors?.firstName && (
                    <Styled.Error>{errors?.firstName}</Styled.Error>
                  )}
                </Styled.FormData>
                <Styled.FormData className="formData">
                  <label className="label">{Checkout.Last_name}</label>
                  <input
                    className="input"
                    type="text"
                    id="lastName"
                    onChange={handleValue}
                    value={checkoutData?.lastName}
                  />
                  {errors?.lastName && (
                    <Styled.Error>{errors?.lastName}</Styled.Error>
                  )}
                </Styled.FormData>
              </Styled.FormDataContainer>
              <Styled.FormDataContainer>
                <Styled.FormData className="formData">
                  <label className="label">{Checkout.Email_address}</label>
                  <input
                    className="email-input"
                    type="email"
                    id="email"
                    onChange={handleValue}
                    value={checkoutData?.email}
                  />
                  {errors?.email && (
                    <Styled.Error>{errors?.email}</Styled.Error>
                  )}
                </Styled.FormData>
                <Styled.FormData className="formData">
                  <label className="label">{Checkout.Phone}</label>
                  <input
                    className="input"
                    type="text"
                    id="phone"
                    onChange={handleValue}
                    value={checkoutData?.phone}
                    maxlength={"14"}
                  />
                  {errors?.phone && (
                    <Styled.Error>{errors?.phone}</Styled.Error>
                  )}
                </Styled.FormData>
              </Styled.FormDataContainer>
              <Styled.FormDataContainer>
                <Styled.FormData className="formData">
                  <label className="label">{Checkout.Company_name}</label>
                  <input
                    className="input"
                    type="text"
                    id="companyName"
                    onChange={handleValue}
                    value={checkoutData?.companyName}
                  />
                  {errors?.companyName && (
                    <Styled.Error>{errors?.companyName}</Styled.Error>
                  )}
                </Styled.FormData>
                <Styled.FormData className="formData">
                  <label className="label">Country</label>
                  <select
                    id="country"
                    onChange={handleValue}
                    name="countryList"
                    className="input"
                  >
                    <option>{selectedRadio?.Country?.name}</option>
                    {countryList?.map((item) => {
                      return (
                        <>
                          <option value={item?.id}>{item?.name}</option>;
                        </>
                      );
                    })}
                  </select>
                </Styled.FormData>
              </Styled.FormDataContainer>
              <Styled.FormDataContainer>
                <Styled.FormData className="formData">
                  <label className="label">{Checkout.State_County}</label>
                  <select
                    id="state"
                    name="StateCountyList"
                    onChange={handleValue}
                    className="input"
                  >
                    <option>{selectedRadio?.State?.name}</option>
                    {state?.map((item) => {
                      return <option value={item?.id}>{item?.name}</option>;
                    })}
                  </select>
                </Styled.FormData>
                <Styled.FormData className="formData">
                  <label className="label"> {Checkout.Town_City}</label>
                  <select id="city" className="input" onChange={handleValue}>
                    <option>{selectedRadio?.City?.name}</option>
                    {cities?.map((item) => {
                      return <option value={item?.id}>{item?.name}</option>;
                    })}
                  </select>
                </Styled.FormData>
              </Styled.FormDataContainer>
              <Styled.FormData className="formData">
                <label className="label">{Checkout.Street_address}</label>
                <Styled.InputField>
                  <input
                    className="input"
                    type="text"
                    id="address"
                    onChange={handleValue}
                    value={checkoutData?.address}
                  />
                  {errors?.address && (
                    <Styled.Error>{errors?.address}</Styled.Error>
                  )}
                  {/* <input
                    className="input"
                    type="text"
                    id="address"
                    onChange={handleValue}
                    value={checkoutData?.address}
                  /> */}
                </Styled.InputField>
              </Styled.FormData>
              <Styled.FormData className="formData">
                <label className="label">{Checkout.Postcode_ZIP}</label>
                <Styled.InputField>
                  <input
                    className="input"
                    type="text"
                    id="zipCode"
                    onChange={handleValue}
                    value={checkoutData?.zipCode}
                  />
                  {errors?.zipCode && (
                    <Styled.Error>{errors?.zipCode}</Styled.Error>
                  )}
                </Styled.InputField>
              </Styled.FormData>
              <Styled.FormData className="bill">
                <Checkbox
                  className="bill"
                  onChange={handleSaveBilling}
                  value={1}
                >
                  {Checkout.saveBilling}
                </Checkbox>
              </Styled.FormData>
            </form>
          </Styled.Container>
        </Styled.FirstConatiner>
        <Styled.SecondContainer>
          <Styled.MidContainer className="container">
            <Styled.Purchase>
              <div>{Checkout.Purchase_summary}</div>
            </Styled.Purchase>
            <div className="subtotal">
              <Styled.Subtotal>
                <div>{Checkout.Subtotal}</div>
                <div className="number">
                  <BsCurrencyPound />
                  {cartTotal}
                </div>
              </Styled.Subtotal>
              <Styled.Line />
              <Styled.Total>
                <div>{Checkout.Total}</div>
                <div className="number">
                  <BsCurrencyPound />
                  {cartTotal}
                </div>
              </Styled.Total>
            </div>
            <Styled.Payment>{Checkout.Payment_method}</Styled.Payment>
            <Styled.RadioInput>
              <input
                type="radio"
                name="paymentType"
                id="paymentType"
                value={"paypal"}
                onChange={checkPaymentType}
                onClick={() => setShowBraintreeDropIn(false)}
                disabled={disabledRadio}
                className="radioPay"
              />
              <div style={{ width: "100%" }}>
                <Styled.ButtonBackground className="bottom">
                  <Styled.PaypalText>
                    <MdOutlineCreditCard className="cardicon" />
                    Paypal
                  </Styled.PaypalText>
                  <div>
                    <img src={paypal} alt="paypal" className="paypal" />
                  </div>
                </Styled.ButtonBackground>
              </div>
            </Styled.RadioInput>
            <Styled.RadioInput className="bottom">
              <input
                type="radio"
                value={"card"}
                onClick={() => setShowBraintreeDropIn(true)}
                name="paymentType"
                id="paymentType"
                onChange={checkPaymentType}
                disabled={disabledRadio}
                className="radioPay"
              />
              <Styled.ButtonBackground>
                <Styled.PaypalText>
                  <MdOutlineCreditCard className="cardicon" />
                  Credit card/Debit
                </Styled.PaypalText>
                <div>
                  <img src={visa} alt="visaImage" className="payVisa" />
                  <img
                    src={masterCard}
                    alt="masterCard"
                    className="paymaster"
                  />
                  <img src={maestro} alt="maestro" className="paypal" />
                </div>
              </Styled.ButtonBackground>
            </Styled.RadioInput>
            <div
              style={{
                display: `${showBraintreeDropIn ? "block" : "none"}`,
              }}
            >
              <div id={"braintree-drop-in-div"} />
            </div>
            <Styled.ParagraphGoogle>
              {Checkout.Text} <a href="google.com">{Checkout.Terms}</a>
            </Styled.ParagraphGoogle>
            {paymentGatewayType === "card" ? (
              <Styled.BuyCourseButton>
                <Button
                  className="buy_course"
                  title={Checkout.Buy_course}
                  onClick={() => handleBuyCourse()}
                />
              </Styled.BuyCourseButton>
            ) : (
              <div>
                {braintreeToken ? (
                  <PayPalScriptProvider
                    options={{
                      clientId: "test",
                      components: "buttons",
                      // dataUserIdToken: "your-tokenization-key-here",
                      dataClientToken: braintreeToken,
                      intent: "capture",
                      vault: false,
                    }}
                    deferLoading={true}
                  >
                    <ButtonWrapper
                      currency={"USD"}
                      checkoutData={checkoutData}
                      cartTotal={cartTotal}
                    />
                  </PayPalScriptProvider>
                ) : (
                  <Skeleton />
                )}
              </div>
            )}
          </Styled.MidContainer>
        </Styled.SecondContainer>
      </Styled.MainContainer>
      {isPayment && <Styled.NextLineDeclare />}
    </>
  );
};
