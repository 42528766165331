import HttpApis from "../services/HttpApis";
import { useDispatch } from "react-redux";

import {
  declarationFormFail,
  declarationFormStart,
  declarationFormSuccess,
} from "../redux/slices/declarationSlice";

const useDeclarationHook = () => {
  const dispatch = useDispatch();

  const declareAction = (declareData) => async () => {
    try {
      dispatch(declarationFormStart());
      const res = await HttpApis.declarationForm(declareData);
      dispatch(declarationFormSuccess(res.data));
      return res?.data;
    } catch (error) {
      dispatch(declarationFormFail(error.message));
      return null;
    }
  };
  
  const handleData = async (declareData) => {
    const data = await dispatch(declareAction(declareData));
    return data;
  };

  const userTypeConfirmationEmailApi = async (data) => {
    const headers = {
      "Content-type": "multipart/form-data"
    }
    try {
      const response = await HttpApis.userTypeConfirmationEmail(data, headers);
      return response?.data;
    } catch (error) {
      console.error("Error", error);
      return null;
    }
  };

  //userTypeConfirmation
  const userConfirmationApi = (data) => async () => {
    try {
      dispatch(declarationFormStart());
      const response = await HttpApis.userTypeConfirmation(data);
      dispatch(declarationFormSuccess(response?.data));
      return response?.data;
    } catch (error) {
      dispatch(declarationFormFail(error.message));
    }
  };
  const handleConfirmation = async (data) => {
    const res = await dispatch(userConfirmationApi(data));
    return res;
  };

  return {
    handleData,
    userConfirmationApi,
    handleConfirmation,
    userTypeConfirmationEmailApi,
  };
};
export default useDeclarationHook;
