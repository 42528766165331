import * as Styled from "./style";
import Button from "../../Atoms/Button/button";
import DropDownHeader from "../../../containers/DropDownHeader/DropDownHeader";
import React, { useEffect, useState } from "react";
import logo from "../../../assets/Svg/AVTAV_logo.svg";
import navbg from "../../../assets/image/navbg.jpg";
import useCartHook from "../../../hooks/CartHook";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { constant } from "../../../constants/Constants";
import { Routes } from "../../../routes/routes";
import {
  MdArrowDropDown,
  MdMenu,
  MdNotifications,
  MdShoppingCart,
} from "react-icons/md";
import { NavData } from "./data";
import { Popover } from "antd";
import HttpApis from "../../../services/HttpApis";

export const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userData = useSelector((state) => state.authReducer.user);

  const cartList = useSelector((state) => state?.cartReducer?.cart?.data);
  const [open, setOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [placement, setPlacement] = useState("left");
  const [isSideOpen, setIsSideOpen] = useState(false);
  const showDrawer = () => {
    setOpen(!open);
  };
  const onClose = () => {
    setOpen(false);
  };
  const [openNotification, setOpenNotification] = useState(false);
  const [notifications, setNotifications] = useState();
  const [notificationCount, setNotificationCount] = useState(0);
  const { fetchCart } = useCartHook();
  useEffect(() => {
    fetchCart();
  }, []);

  const handleNotification = () => {
    setOpenNotification(!openNotification);
  };

  const fetch_notifications = async () => {
    if (userData) {
      try {
        const response = await HttpApis.getNotifications();
        setNotifications(response?.data?.data?.cources);
        setNotificationCount(response?.data?.data?.count);
        return response;
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    fetch_notifications();
  }, [userData]);

  const handleCloseNotifications = async (v) => {
    if (!v) {
      const response = await HttpApis.clearNotification();
      if (response?.status === 200) {
        fetch_notifications();
      }
      return response;
    }
  };

  const [path, setPath] = useState("/");
  useEffect(() => {
    setPath(location.pathname);
  }, [location.pathname]);
  const avtarfirstName =
    userData && userData?.firstName.charAt(0)
      ? userData?.firstName.charAt(0).toUpperCase()
      : "";
  const avtarLastName =
    userData && userData?.lastName.charAt(0)
      ? userData?.lastName.charAt(0).toUpperCase()
      : "";
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    setIsSideOpen(!isSideOpen);
  };

  const closeDrawerOnResize = () => {
    if (window.innerWidth > 768) {
      setOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", closeDrawerOnResize);
    return () => {
      window.removeEventListener("resize", closeDrawerOnResize);
    };
  }, []);
  const filteredList = NavData?.filter((item) => {
    return !item?.except?.includes(userData?.type);
  });

  const notificationText = notifications?.map((item) => {
    return (
      <>
        <Styled.Box>{item?.notification_text}</Styled.Box>
      </>
    );
  });

  return (
    <>
      <Styled.Background>
        <img src={navbg} width="100%" alt="navbg" />

        <Styled.Container>
          <MdMenu className="hamburger" onClick={showDrawer} color={"white"} />
          <Styled.ImgCr>
            <img src={logo} alt="logo" width="100%" />
          </Styled.ImgCr>
          <Styled.Draw
            onClose={onClose}
            open={open}
            isSideOpen={isSideOpen}
            placement={placement}
            width={450}
            style={{
              backgroundColor: "#16384e",
            }}
          >
            <Styled.SideBarOpen>
              {NavData?.map((item, id) => {
                return (
                  <Styled.ItemsSide
                    key={id}
                    className="activeSideBar"
                    onClick={() => navigate(item?.path)}
                  >
                    {item?.name}
                    {item?.children && item?.children.length > 0 && (
                      <MdArrowDropDown />
                    )}
                    {item?.children && item?.children?.length > 0 && (
                      <Styled.DropdownContainer>
                        {item?.children?.map((subItem) => {
                          if (subItem?.id === item?.id) {
                            return (
                              <Styled.DropdownItems>
                                {subItem?.name}
                              </Styled.DropdownItems>
                            );
                          }
                        })}
                      </Styled.DropdownContainer>
                    )}
                  </Styled.ItemsSide>
                );
              })}
            </Styled.SideBarOpen>
          </Styled.Draw>

          <Styled.ImgContainer>
            <img src={logo} alt="logo" width="100%" />
          </Styled.ImgContainer>
          <Styled.NavContainer>
            {filteredList?.map((item, id) => {
              return (
                <Styled.NavItems
                  key={id}
                  className={item?.path === path ? "active" : null}
                  onClick={() => navigate(item?.path)}
                >
                  {item?.name}
                  {item?.children && item?.children.length > 0 && (
                    <MdArrowDropDown />
                  )}
                  {item?.children && item?.children?.length > 0 && (
                    <Styled.DropdownContainer>
                      {item?.children?.map((subItem) => {
                        if (subItem?.id === item?.id) {
                          return (
                            <Styled.DropdownItems>
                              {subItem?.name}
                            </Styled.DropdownItems>
                          );
                        }
                      })}
                    </Styled.DropdownContainer>
                  )}
                </Styled.NavItems>
              );
            })}
          </Styled.NavContainer>
          {!userData ? (
            <Styled.NavButton>
              <Button
                className="login-button"
                title={constant.login}
                onClick={() => navigate(Routes?.Signin)}
              />
              <Button
                className="register-button"
                title={constant.register}
                onClick={() => navigate(Routes?.askingPage)}
              />
            </Styled.NavButton>
          ) : (
            <Styled.Icons>
              <Popover
                arrow={false}
                overlayStyle={{
                  width: "20%",
                }}
                overlayInnerStyle={{
                  background: "#16384E",
                }}
                content={
                  <>
                    <Styled.NotificationHeading>
                      <div className="cartLength">
                        <MdNotifications className="notification" />
                        {notificationCount > 0 ? (
                          <span className="cartList"></span>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="heading">Notifications</div>
                      <div className="count">{`(${notificationCount} new)`}</div>
                    </Styled.NotificationHeading>
                    <Styled.Notification>
                      {notificationText?.length > 0
                        ? notificationText
                        : "No New Notification"}
                    </Styled.Notification>
                  </>
                }
                trigger="click"
                open={openNotification}
                onOpenChange={handleNotification}
                afterOpenChange={(v) => {
                  handleCloseNotifications(v);
                }}
              >
                <div className="cartLength">
                  <MdNotifications className="notification" />
                  {notificationCount > 0 ? (
                    <span className="cartList"></span>
                  ) : (
                    <></>
                  )}
                </div>
              </Popover>
              {userData?.type === "Corporate User" ||
              userData?.type === "Single User" ? (
                <div className="cartLength">
                  <MdShoppingCart
                    className="cart"
                    onClick={() => navigate(Routes?.multiCart)}
                  />
                  <span className="cartList">{cartList?.length}</span>
                </div>
              ) : (
                <></>
              )}
              <DropDownHeader
                trigger={avtarfirstName + "" + avtarLastName}
                userData={userData}
              />
            </Styled.Icons>
          )}
        </Styled.Container>
      </Styled.Background>
    </>
  );
};
