import * as Styled from "./style";
import Button from "../../../components/Atoms/Button/button";
import React, { useState } from "react";
import { course } from "./data";
import HttpApis from "../../../services/HttpApis";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Rate } from "antd";
import { reviewListSuccess } from "../../../redux/slices/reviewSllice";

const RatingModal = ({ name, coursesDetails, isModalOpen, setIsModalOpen }) => {
  const userId = useSelector((state) => state?.authReducer?.user?.id);
  const courseName = coursesDetails?.course?.slug;
  const courseTitle = coursesDetails?.course?.title;
  const courseId = coursesDetails?.course?.id;
  const [review, setReview] = useState({
    rating: 0,
    comment: "",
    userId: userId,
    courseId: courseId?.toString(),
  });
  const dispatch = useDispatch();
  const getReviewList = async () => {
    try {
      const response = await HttpApis.getReviewListApi(courseName);
      dispatch(reviewListSuccess(response?.data));
    } catch (error) {
      return null;
    }
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleText = (e) => {
    const value = e.target.value;

    setReview({ ...review, [e?.target?.name]: value });
  };
  const handleSubmit = async () => {
    const response = await HttpApis.postReviewApi(review);
    if (response?.status === 200) {
      setReview({});
      setIsModalOpen(false);
      getReviewList();
    }
    return response;
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <a type="primary" onClick={showModal}>
        {name}
      </a>
      <Styled.ModalContainer
        style={{
          top: 20,
        }}
        width={936}
        footer={null}
        closeIcon={false}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Styled.ModalItems>
          <div className="modalrate">
            <div className="rate">{course.modalrating}</div>
          </div>
          <div className="courses">
            <div className="namecourse">{course.namecourse}</div>
            <div className="gsat">{courseTitle}</div>
          </div>
          <Styled.LineRating />
          <Flex gap="middle" vertical className="stars">
            <Rate
              name="rating"
              onChange={(e) =>
                handleText({ target: { value: e, name: "rating" } })
              }
              value={review?.rating}
            />
          </Flex>
          <div className="textareas">
            <Styled.TextRating
              placeholder={course.comnt}
              name="comment"
              value={review?.comment}
              onChange={(e) => handleText(e)}
            />
          </div>
          <div className="submit">
            <Button
              title="Submit Rating"
              className="ratings"
              onClick={handleSubmit}
            />
          </div>
        </Styled.ModalItems>
      </Styled.ModalContainer>
    </>
  );
};
export default RatingModal;
