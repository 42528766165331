import paymenttick from "../../assets/Svg/paymenttick.svg";

export const browseData = {
  declare: "Declaration",
  success: "Payment Successful and Declaration sent for approval ",
  aviation: "for Aviation Security Courses",
  detail: "Course Details",
  course: "Course name",
  gsat: "General Security Awareness Training (GSAT)",
  q: "Qty",
  x: "x1",
  price: "Price (per license):",
  sign: "£7.00",
  sole: "| Sole Trader Declaration",
  back: " Background Check Declaration",
  went: "Your payment went through successfully.",
  team: " Your declaration form has been received by AVTAV Training Team.",
  contacted:`You will be contacted shortly with the next steps before we give
  you access to the course material.`,
  corporate: " | For Corporate User"
};
export const paymentTick = [
  {
    id: 0,
    img: paymenttick,
  },
  {
    id: 1,
    img: paymenttick,
  },
  {
    id: 2,
    img: paymenttick,
  },
  {
    id: 3,
    img: paymenttick,
  },
  {
    id: 4,
    img: paymenttick,
  },
];
