import * as Styled from "./style";
import React from "react";
import { Space } from "antd";
import { HeaderTraining } from "../Header/HeaderTraining";
import { cong } from "./data";
import Footer from "../../../components/Organisms/Footer/Footer";

const CourseCompletion = () => {
  return (
    <>
      <HeaderTraining />
      <Styled.MainComp className="container">
        <Styled.Congratulate>
          <div className="con">{cong.con}</div>
          <div>
            <div className="success">
              {cong.success}
              <span className="xx">{cong.xx}</span>
              {cong.please}
            </div>
          </div>
          <div className="hesitate">
            <div>{cong.request}</div>
            <div>{cong.hesitate}</div>
          </div>
          <div className="kind">
            <div>{cong.kindest}</div>
            <div>{cong.avtav}</div>
          </div>
        </Styled.Congratulate>
        <Styled.ProgressComp>
          <Space wrap>
            <Styled.ProgressCircle
              type="circle"
              percent={100}
              format={(percent) => `${percent}% Complete`}
              strokeColor="#5193AA"
              trailColor="#15374D"
              strokeWidth="8"
              size={300}
              colorBgContainer="#F1FBFF"
            />
          </Space>
        </Styled.ProgressComp>
      </Styled.MainComp>
      <Footer />
    </>
  );
};

export default CourseCompletion;
