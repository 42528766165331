import styled from "styled-components";
import { color } from "../../theme/Color";

export const Container = styled.div`
  display: flex;
  overflow-x: hidden;
`;

export const SidebarContainer = styled.div`
  flex: 0.3;
`;

export const ImageContainer = styled.div`
  background: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
`;

export const FormContainer = styled.div`
  flex: 0.7;
`;

export const Head = styled.div`
  padding-bottom: 28px;
`;
export const MainContainer = styled.div`
  height: 100%;

  padding: 3% 4% 0% 4%;
`;
export const NameContainer = styled.div`
  margin-top: 155px;
  margin-bottom: 17px;
  color: ${color._5193aa};
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: 95%;
  .helloText {
    color: ${color._000};
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
  }
`;
export const ThankYouText = styled.div`
  margin-bottom: 5px;
  color: ${color._6d7d8b};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 133%;
`;
export const Information = styled.div`
  color: ${color._6d7d8b};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 133%;
  width: 50%;
  margin-bottom: 33px;
  .employer {
    color: ${color._6d7d8b};
    font-size: 14px;
    font-style: italic;
    font-weight: 600;
    line-height: 133%;
  }
`;
export const Proceed = styled.div`
  margin-bottom: 131px;
  color: ${color._5193aa};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 133%;
  width: 310px;
`;
export const Maincontainer = styled.div`
  padding-left: 11%;
`;
export const Terms = styled.div`
  color: var(--Question-Color, ${color._007097});
  margin: 40px auto;
  display: flex;
  justify-content: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const Lines = styled.div`
  width: 81%;
  padding-bottom: 63px;
  .web {
    color: ${color._6d7d8b};
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 136.184%;
  }
  .about {
    margin-top: 30px;
    color: var(--Question-Color, ${color._007097});
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 136.184%;
  }
  .register {
    margin-top: 18px;
    color: ${color._6d7d8b};
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 136.184%;
  }
`;

export const MainData = styled.div`
  width: 84%;
  padding-bottom: 20px;
  .web {
    color: ${color._6d7d8b};
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 136.184%;
  }
  .about {
    margin-top: 30px;
    color: var(--Question-Color, ${color._007097});
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 136.184%;
  }
  .register {
    margin-top: 18px;
    color: ${color._6d7d8b};
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 136.184%;
  }
  .regist {
    margin-top: 18px;
    color: ${color._6d7d8b};
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 136.184%;
    text-decoration: underline;
  }
`;
export const TermsDiv = styled.div`
  color: var(--Question-Color, #007097);
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
export const Accept = styled.div`
  width: 84%;
  padding-bottom: 63px;
  .avtavLtd {
    color: ${color._6d7d8b};
    font-family: Open Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 126%;
  }
  .web {
    color: ${color._6d7d8b};
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 136.184%;
  }
  .about {
    margin-top: 30px;
    color: var(--Question-Color, ${color._007097});
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 136.184%;
  }
  .register {
    margin-top: 18px;
    color: ${color._6d7d8b};
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 136.184%;
  }
  .regist {
    color: ${color._6d7d8b};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 126%;
    text-decoration-line: underline;
  }
`;
export const LineBack = styled.div`
  width: 82%;
  height: 1px;
  background: ${color._6090a8};
  margin: 10px auto;
`;
export const Lined = styled.div`
  width: 84%;
  padding-bottom: 63px;
  .web {
    color: ${color._6d7d8b};
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 136.184%;
  }
  .about {
    margin-top: 30px;
    color: var(--Question-Color, ${color._007097});
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 136.184%;
  }
  .register {
    margin-top: 18px;
    color: ${color._6d7d8b};
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 136.184%;
  }
`;
export const Names = styled.div`
  gap: 39px;
  display: flex;
  margin-top: 20px;
`;
export const MainCotainer = styled.div`
  .to {
    color: ${color._6d7d8b};
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .order {
    margin-top: 20px;
  }
  .suite {
    color: ${color._000000};
    margin-top: 10px;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-bottom: 20px;
  }
  .checkBox {
    margin: 33px 0px;
  }
`;
export const CheckMains = styled.div`
  .prompts {
    margin-top: 17px;
  }
  .checked {
    margin-top: 5px;
  }

  .terms {
    color: ${color._177a9c};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
    text-decoration-line: underline;
  }
  margin-top: 50px;
  .check {
    color: ${color._15374d};
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
  }
`;
export const Label = styled.label`
  color: ${({ condition }) => (condition ? color._565656 : color._000000)};
  padding-bottom: 4px;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 95%;
  .star {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
    color: ${color._f20000};
  }
  gap: 3px;
  display: flex;
`;
export const Error = styled.div`
  color: ${color._ff0000};
`;
export const Input = styled.input`
  outline: none;
  width: 313px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 3px;
  padding: 10px;
  border: 1px solid ${color._ebebeb};
  background: ${color._fbfbfb};
`;
export const InputAddress = styled.input`
  outline: none;
  width: 666px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 3px;
  padding: 10px;
  border: 1px solid ${color._ebebeb};
  background: ${color._fbfbfb};
`;
export const TextArea = styled.div`
  margin-top: 44px;
  .cancelation {
    color: ${color._000000};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 195%;
  }
`;
export const InputText = styled.textarea`
  width: 666px;
  height: 108px;
  flex-shrink: 0;
  border-radius: 3px;
  border: 1px solid ${color._ebebeb};
  background: ${color._fbfbfb};
  margin-top: 10px;
  outline: none;
  padding: 10px;
`;
export const Submit = styled.div`
  .submit {
    background: ${color._0073DE};
    border: none;
    color: ${color._ffffff};
    width: 103px;
    height: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 54px;
    font-weight: 500;
  }
`;
