import { useDispatch } from "react-redux";
import HttpApis from "../services/HttpApis";
import {
  postProgressSuccess,
  postProgressFail,
  getProgressSuccess,
  postCheckListSuccess,
  postCheckListFail,
  getCheckListSuccess,
  getCheckListFail,
} from "../redux/slices/SliderSlice";

export const useSliderDetails = () => {
  const dispatch = useDispatch();

  const updateProgress = (param) => async (dispatch) => {
    try {
      const res = await HttpApis.postProgress(param);
      dispatch(postProgressSuccess(res));
      return res;
    } catch (error) {
      dispatch(postProgressFail(error?.message));
      return null;
    }
  };

  const postProgressList = async (param) => {
    const res = await dispatch(updateProgress(param));

    return res;
  };

  const getProgress = async (courseId) => {
    try {
      const response = await HttpApis.getProgressApi(courseId);
      dispatch(getProgressSuccess(response?.data));
    } catch (error) {
      console.error("error");
      return null;
    }
  };

  const updateCheckList = (param) => async (dispatch) => {
    try {
      const res = await HttpApis.postCheckList(param);
      dispatch(postCheckListSuccess(res));
      return res;
    } catch (error) {
      dispatch(postCheckListFail(error?.message));
      return null;
    }
  };

  const postCheckList = async (param) => {
    const res = await dispatch(updateCheckList(param));
    return res;
  };

  const getCheckList = async (courseId) => {
    try {
      const response = await HttpApis.getCheckListApi(courseId);
      dispatch(getCheckListSuccess(response?.data || {}));
    } catch (error) {
      console.error("error");
      return null;
    }
  };
  return { postProgressList, getProgress, postCheckList, getCheckList };
};
