import * as Styled from "./style";
import Button from "../../../components/Atoms/Button/button";
import React, { useEffect, useState } from "react";
import useAuthHook from "../../../hooks/AuthHook";
import { FaPen } from "react-icons/fa";
import { FiX } from "react-icons/fi";
import { useSelector } from "react-redux";
import { Profile } from "../businessConstant";
import { message } from "antd";

const BusinessUserProfile = () => {
  const [errors, setErrors] = useState({});
  const { handleEditUser } = useAuthHook();
  const [isEdit, setIsEdit] = useState(false);
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    companyName: "",
    landingNo: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    postcode: "",
  });

  const corporateData = useSelector((state) => state.authReducer.user);

  const firstName =
    corporateData && corporateData?.firstName
      ? corporateData?.firstName.charAt(0).toUpperCase() +
        corporateData?.firstName?.slice(1)
      : "";
  const lastName =
    corporateData && corporateData?.lastName
      ? corporateData?.lastName.charAt(0).toUpperCase() +
        corporateData?.lastName?.slice(1)
      : "";
  const avtarfirstName =
    corporateData && corporateData?.firstName
      ? corporateData?.firstName.charAt(0).toUpperCase()
      : "";
  const avtarLastName =
    corporateData && corporateData?.lastName
      ? corporateData?.lastName.charAt(0).toUpperCase()
      : "";

  useEffect(() => {
    if (corporateData) {
      setData({
        firstName: corporateData?.firstName,
        lastName: corporateData?.lastName,
        email: corporateData?.email,
        mobile: corporateData?.mobile,
        companyName: corporateData?.companyName,
        companyRegNo: corporateData?.companyRegNo,
        landingNo: corporateData?.landingNo,
        addressLine1: corporateData?.addressLine1,
        addressLine2: corporateData?.addressLine2,
        city: corporateData?.city,
        postcode: corporateData?.postcode,
      });
    }
  }, [corporateData]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setErrors((prevErrors) => {
      let newErrors = { ...prevErrors };

      if (!value.trim()) {
        newErrors[name] = "Field cannot be empty";
      } else if (name === "mobile" && !/^\d{10,15}$/.test(value)) {
        newErrors[name] = "Invalid mobile number";
      } else if (
        name === "email" &&
        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(value)
      ) {
        newErrors[name] = "Invalid email";
      } else if (name === "landingNo" && !/^\d{10,15}$/.test(value)) {
        newErrors[name] = "Invalid landing number";
      } else {
        delete newErrors[name];
      }

      return newErrors;
    });

    setData((pre) => ({
      ...pre,
      [name]: value,
    }));
  };

  const handleEdit = () => {
    setIsEdit(true);
  };

  const handleCancel = () => {
    setIsEdit(false);
  };

  const handleSave = (corporateData) => {
    const requiredFields = [
      "firstName",
      "lastName",
      "mobile",
      "email",
      "companyName",
      "addressLine1",
      "landingNo",
      "companyRegNo",
    ];
    const emptyFields = requiredFields.filter((field) => !data[field]);

    if (emptyFields.length > 0 || Object.keys(errors).length > 0) {
      message.error("Please fill out the required details");
      return;
    }
    handleEditUser({ ...data, type: corporateData?.type })
      .then((response) => {
        if (response) {
          setIsEdit(false);
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  return (
    <>
      <div className="container">
        <Styled.MainContentContainer>
          <Styled.Container>
            <Styled.Heading>
              <div>{Profile?.profile}</div>
            </Styled.Heading>
          </Styled.Container>

          <Styled.ProfileData>
            <Styled.FileData>
              <div>
                <Styled.Label>
                  {avtarfirstName + " " + avtarLastName}
                </Styled.Label>
              </div>
              {isEdit ? (
                <Styled.SaveButton>
                  <Button
                    title={
                      <>
                        <FiX className="crossIcon" /> {Profile?.cancel}
                      </>
                    }
                    className={"cancel"}
                    onClick={handleCancel}
                  />

                  <Button
                    title={Profile?.save}
                    className={"save"}
                    onClick={() => handleSave(corporateData)}
                  />
                </Styled.SaveButton>
              ) : (
                <Styled.EditButton>
                  <Button
                    className="editbutton"
                    icon={<FaPen />}
                    title={"Edit Profile"}
                    onClick={handleEdit}
                  />
                  <div className="line" />
                </Styled.EditButton>
              )}
            </Styled.FileData>
            <Styled.Line lineMargin="43px 0px 17px 0px" />

            {isEdit ? (
              <div>
                <Styled.CompanyData>
                  <Styled.Heads>{Profile?.about}</Styled.Heads>
                  <Styled.Information>
                    <Styled.NumberMailData>
                      <Styled.Number>
                        <div className="heading">{Profile?.firstName}</div>
                        <Styled.EditInput
                          type="text"
                          name="firstName"
                          value={data?.firstName}
                          onChange={handleChange}
                        />
                        {errors.firstName && (
                          <Styled.Error>{errors.firstName}</Styled.Error>
                        )}
                      </Styled.Number>
                      <Styled.Mail>
                        <div className="heading">{Profile?.lastName}</div>
                        <Styled.EditInput
                          type="text"
                          name="lastName"
                          value={data?.lastName}
                          onChange={handleChange}
                        />
                        {errors.lastName && (
                          <Styled.Error>{errors.lastName}</Styled.Error>
                        )}
                      </Styled.Mail>
                    </Styled.NumberMailData>
                    <Styled.NumberMailData>
                      <Styled.Number>
                        <div className="heading">{Profile?.mobile}</div>
                        <Styled.EditInput
                          type="text"
                          name="mobile"
                          value={data?.mobile}
                          onChange={handleChange}
                        />
                        {errors.mobile && (
                          <Styled.Error>{errors.mobile}</Styled.Error>
                        )}
                      </Styled.Number>
                      <Styled.Mail>
                        <div className="heading">{Profile?.email}</div>
                        <Styled.EditInput
                          type="email"
                          name="email"
                          value={data?.email}
                          onChange={handleChange}
                          className="email-input"
                        />
                        {errors.email && (
                          <Styled.Error>{errors.email}</Styled.Error>
                        )}
                      </Styled.Mail>
                    </Styled.NumberMailData>
                  </Styled.Information>
                </Styled.CompanyData>
                <Styled.CompanyData MarginTop="41px">
                  <Styled.Heads>{Profile?.details}</Styled.Heads>
                  <Styled.Information>
                    <Styled.Number>
                      <div className="heading">{Profile?.companyName}</div>
                      <Styled.EditInput
                        type="text"
                        name="companyName"
                        value={data?.companyName}
                        onChange={handleChange}
                      />
                      {errors.companyName && (
                        <Styled.Error>{errors.companyName}</Styled.Error>
                      )}
                    </Styled.Number>
                    <Styled.NumberMailData>
                      <Styled.Number>
                        <div className="heading">{Profile?.company}</div>
                        <Styled.EditInput
                          type="text"
                          name="addressLine1"
                          value={data?.addressLine1}
                          onChange={handleChange}
                        />
                        {errors.addressLine1 && (
                          <Styled.Error>{errors.addressLine1}</Styled.Error>
                        )}
                      </Styled.Number>
                      <Styled.Mail>
                        <div className="heading">{Profile?.landlines}</div>
                        <Styled.EditInput
                          type="text"
                          name="landingNo"
                          value={data?.landingNo}
                          onChange={handleChange}
                        />
                        {errors.landingNo && (
                          <Styled.Error>{errors.landingNo}</Styled.Error>
                        )}
                      </Styled.Mail>
                    </Styled.NumberMailData>
                    <Styled.Line lineMargin="18px 0px 3px 0px" />
                    <Styled.NumberMailData>
                      <Styled.Number>
                        <div className="heading">{Profile?.regNo}</div>
                        <Styled.EditInput
                          type="text"
                          name="companyRegNo"
                          value={data?.companyRegNo}
                          onChange={handleChange}
                        />
                        {errors.companyRegNo && (
                          <Styled.Error>{errors.companyRegNo}</Styled.Error>
                        )}
                      </Styled.Number>
                      <Styled.Mail>
                        <div className="heading">{Profile?.nature}</div>
                        <div className="data">{Profile?.Airline}</div>
                      </Styled.Mail>
                    </Styled.NumberMailData>
                  </Styled.Information>
                </Styled.CompanyData>

                <Styled.CompanyData MarginTop="30px">
                  <Styled.Heads>{Profile?.emp}</Styled.Heads>
                  <Styled.Information>
                    <div>
                      <Styled.Number>
                        <div className="data">{Profile?.type}</div>
                      </Styled.Number>
                      <Styled.Line lineMargin="17px 0px" />
                      <Styled.Number>
                        <div className="heading">{Profile?.aviation}</div>
                        <Styled.Check className="data">
                          {Profile?.yes}
                        </Styled.Check>
                      </Styled.Number>
                    </div>
                  </Styled.Information>
                </Styled.CompanyData>
              </div>
            ) : (
              <div>
                <Styled.CompanyData>
                  <Styled.Heads>{Profile?.about}</Styled.Heads>
                  <Styled.Information>
                    <Styled.Airways>
                      {firstName + " " + lastName}
                    </Styled.Airways>
                    <Styled.NumberMail>
                      <Styled.Number>
                        <div className="heading">{Profile?.mobile}</div>
                        <div className="data">
                          {corporateData?.mobile ? corporateData?.mobile : ""}
                        </div>
                      </Styled.Number>
                      <Styled.Mail>
                        <div className="heading">{Profile?.email}</div>
                        <div className="data">
                          {corporateData?.email ? corporateData?.email : ""}
                        </div>
                      </Styled.Mail>
                    </Styled.NumberMail>
                  </Styled.Information>
                </Styled.CompanyData>
                <Styled.CompanyData MarginTop="41px">
                  <Styled.Heads>{Profile?.details}</Styled.Heads>
                  <Styled.Information>
                    <Styled.Airways>
                      {corporateData?.companyName
                        ? corporateData?.companyName
                        : ""}
                    </Styled.Airways>
                    <Styled.NumberMail>
                      <Styled.Number>
                        <div className="heading">{Profile?.company}</div>
                        <div className="data">
                          {corporateData?.addressLine1
                            ? corporateData?.addressLine1
                            : ""}
                        </div>
                      </Styled.Number>
                      <Styled.Mail>
                        <div className="heading">{Profile?.landlines}</div>
                        <div className="data">
                          {corporateData?.landingNo
                            ? corporateData?.landingNo
                            : ""}
                        </div>
                      </Styled.Mail>
                    </Styled.NumberMail>
                    <Styled.Line lineMargin="18px 0px 3px 0px" />
                    <Styled.NumberData>
                      <Styled.Number>
                        <div className="heading">{Profile?.regNo}</div>
                        <div className="data">
                          {corporateData?.companyRegNo
                            ? corporateData?.companyRegNo
                            : ""}
                        </div>
                      </Styled.Number>
                      <Styled.Mail>
                        <div className="heading">{Profile?.nature}</div>
                        <div className="data">{Profile?.Airline}</div>
                      </Styled.Mail>
                    </Styled.NumberData>
                  </Styled.Information>
                </Styled.CompanyData>
                <Styled.CompanyData MarginTop="30px">
                  <Styled.Heads>{Profile?.emp}</Styled.Heads>
                  <Styled.Information>
                    <div>
                      <Styled.Number>
                        <div className="data">{Profile?.type}</div>
                      </Styled.Number>
                      <Styled.Line lineMargin="17px 0px" />
                      <Styled.Number>
                        <div className="heading">{Profile?.aviation}</div>
                        <Styled.Check className="data">
                          {Profile?.yes}
                        </Styled.Check>
                      </Styled.Number>
                    </div>
                  </Styled.Information>
                </Styled.CompanyData>
              </div>
            )}
          </Styled.ProfileData>
        </Styled.MainContentContainer>
      </div>
    </>
  );
};

export default BusinessUserProfile;
