import * as Styled from "./style";
import Button from "../../../components/Atoms/Button/button";
import React, { useEffect, useState } from "react";
import useAuthHook from "../../../hooks/AuthHook";
import { FaPen } from "react-icons/fa";
import { FiX } from "react-icons/fi";
import { useSelector } from "react-redux";
import { Profiles } from "./SingleData";
import { message } from "antd";

const SingleProfile = () => {
  const { handleEditUser } = useAuthHook();
  const [errors, setErrors] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    companyName: "",
    landingNo: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    postcode: "",
  });
  const singleProfileData = useSelector((state) => state.authReducer.user);
  const avtarfirstName =
    singleProfileData && singleProfileData?.firstName
      ? singleProfileData?.firstName.charAt(0).toUpperCase()
      : "";
  const avtarLastName =
    singleProfileData && singleProfileData?.lastName
      ? singleProfileData?.lastName.charAt(0).toUpperCase()
      : "";
  const firstName =
    singleProfileData && singleProfileData?.firstName
      ? singleProfileData?.firstName.charAt(0).toUpperCase() +
        singleProfileData?.firstName.slice(1)
      : "";
  const lastName =
    singleProfileData && singleProfileData?.lastName
      ? singleProfileData?.lastName.charAt(0).toUpperCase() +
        singleProfileData?.lastName.slice(1)
      : "";

  useEffect(() => {
    if (singleProfileData) {
      setData({
        firstName: singleProfileData?.firstName,
        lastName: singleProfileData?.lastName,
        email: singleProfileData?.email,
        mobile: singleProfileData?.mobile,
        companyName: singleProfileData?.companyName,
        companyRegNo: singleProfileData?.companyRegNo,
        landingNo: singleProfileData?.landingNo,
        addressLine1: singleProfileData?.addressLine1,
        addressLine2: singleProfileData?.addressLine2,
        city: singleProfileData?.city,
        postcode: singleProfileData?.postcode,
      });
    }
  }, [singleProfileData]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setErrors((prevErrors) => {
      let newErrors = { ...prevErrors };

      if (!value.trim()) {
        newErrors[name] = "Field cannot be empty";
      } else if (name === "mobile" && !/^\d{10,15}$/.test(value)) {
        newErrors[name] = "Invalid mobile number";
      } else if (
        name === "email" &&
        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(value)
      ) {
        newErrors[name] = "Invalid email";
      } else if (name === "landingNo" && !/^\d{10,15}$/.test(value)) {
        newErrors[name] = "Invalid landing number";
      } else {
        delete newErrors[name];
      }

      return newErrors;
    });

    setData((pre) => ({
      ...pre,
      [name]: value,
    }));
  };

  const handleEdit = () => {
    setIsEdit(true);
  };

  const handleCancel = () => {
    setIsEdit(false);
  };

  const handleSave = () => {
    const requiredFields = [
      "firstName",
      "lastName",
      "mobile",
      "email",
      "companyName",
      "addressLine1",
      "landingNo",
      "companyRegNo",
    ];
    const emptyFields = requiredFields.filter((field) => !data[field]);

    if (emptyFields.length > 0 || Object.keys(errors).length > 0) {
      message.error("Please fill out the required details");
      return;
    }
    handleEditUser({ ...data, type: "Single User" })
      .then((response) => {
        if (response) {
          setIsEdit(false);
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  return (
    <>
      <div className="container">
        <Styled.ProfileData>
          <Styled.FileData>
            <div>
              <Styled.Label>
                {avtarfirstName + " " + avtarLastName}
              </Styled.Label>
            </div>
            {isEdit ? (
              <Styled.SaveButton>
                <div className="cross">
                  <Button
                    title={
                      <>
                        <FiX className="crossIcon" /> {Profiles?.cancel}
                      </>
                    }
                    className={"cancel"}
                    onClick={handleCancel}
                  />
                </div>
                <Button
                  title={Profiles?.save}
                  className={"save"}
                  onClick={handleSave}
                />
              </Styled.SaveButton>
            ) : (
              <Styled.SaveButton>
                <Styled.EditButton>
                  <Button
                    className="editbutton"
                    icon={<FaPen />}
                    title={"Edit Address"}
                    onClick={handleEdit}
                  />
                  <div className="line" />
                </Styled.EditButton>
              </Styled.SaveButton>
            )}
          </Styled.FileData>
          <Styled.BreakLine lineMargin="19px 0px 18px 0px" />

          {isEdit ? (
            <div>
              <div>
                <Styled.Heads>{Profiles?.about}</Styled.Heads>
                <Styled.Information>
                  <Styled.NumberMailData>
                    <Styled.Number>
                      <div className="heading">{Profiles?.firstName}</div>
                      <Styled.EditInput
                        type="text"
                        name="firstName"
                        value={data?.firstName}
                        onChange={handleChange}
                      />
                      {errors.firstName && (
                        <Styled.Error>{errors.firstName}</Styled.Error>
                      )}
                    </Styled.Number>
                    <Styled.Mail>
                      <div className="heading">{Profiles?.lastName}</div>
                      <Styled.EditInput
                        type="text"
                        name="lastName"
                        value={data?.lastName}
                        onChange={handleChange}
                      />
                      {errors.lastName && (
                        <Styled.Error>{errors.lastName}</Styled.Error>
                      )}
                    </Styled.Mail>
                  </Styled.NumberMailData>
                  <Styled.NumberMailData>
                    <Styled.Number>
                      <div className="heading">{Profiles?.mobile}</div>
                      <Styled.EditInput
                        type="text"
                        name="mobile"
                        value={data?.mobile}
                        onChange={handleChange}
                      />
                      {errors.mobile && (
                        <Styled.Error>{errors.mobile}</Styled.Error>
                      )}
                    </Styled.Number>
                    <Styled.Mail>
                      <div className="heading">{Profiles?.email}</div>
                      <Styled.EditInput
                        type="email"
                        name="email"
                        value={data?.email}
                        onChange={handleChange}
                        className="email-input"
                      />
                      {errors.email && (
                        <Styled.Error>{errors.email}</Styled.Error>
                      )}
                    </Styled.Mail>
                  </Styled.NumberMailData>
                </Styled.Information>
              </div>
              <Styled.CompanyData MarginTop="41px">
                <Styled.Heads>{Profiles?.details}</Styled.Heads>
                <Styled.Information>
                  <Styled.Number>
                    <div className="heading">{Profiles?.companyName}</div>
                    <Styled.EditInput
                      type="text"
                      name="companyName"
                      value={data?.companyName}
                      onChange={handleChange}
                    />
                    {errors.companyName && (
                      <Styled.Error>{errors.companyName}</Styled.Error>
                    )}
                  </Styled.Number>
                  <Styled.NumberMailData>
                    <Styled.Number>
                      <div className="heading">{Profiles?.company}</div>
                      <Styled.EditInput
                        type="text"
                        name="addressLine1"
                        value={data?.addressLine1}
                        onChange={handleChange}
                      />
                      {errors.addressLine1 && (
                        <Styled.Error>{errors.addressLine1}</Styled.Error>
                      )}
                    </Styled.Number>
                    <Styled.Mail>
                      <div className="heading">{Profiles?.landlines}</div>
                      <Styled.EditInput
                        type="text"
                        name="landingNo"
                        value={data?.landingNo}
                        onChange={handleChange}
                      />
                      {errors.landingNo && (
                        <Styled.Error>{errors.landingNo}</Styled.Error>
                      )}
                    </Styled.Mail>
                  </Styled.NumberMailData>
                  <Styled.BreakLine lineMargin="19px 0px 3px 0px" />
                  <Styled.NumberMailData>
                    <Styled.Number>
                      <div className="heading">{Profiles?.regNo}</div>
                      <Styled.EditInput
                        type="text"
                        name="companyRegNo"
                        value={data?.companyRegNo}
                        onChange={handleChange}
                      />
                      {errors.companyRegNo && (
                        <Styled.Error>{errors.companyRegNo}</Styled.Error>
                      )}
                    </Styled.Number>
                    <Styled.Mail>
                      <div className="heading">{Profiles?.nature}</div>
                      <div className="data">{Profiles?.Airline}</div>
                    </Styled.Mail>
                  </Styled.NumberMailData>
                </Styled.Information>
              </Styled.CompanyData>
              <Styled.CompanyData MarginTop="30px">
                <Styled.Heads>{Profiles?.emp}</Styled.Heads>
                <Styled.Information>
                  <div>
                    <Styled.Number>
                      <div className="data">{Profiles?.type}</div>
                    </Styled.Number>
                    <Styled.BreakLine lineMargin="17px 0px" />

                    <Styled.Number>
                      <div className="heading">{Profiles?.aviation}</div>
                      <div className="data">{Profiles?.yes}</div>
                    </Styled.Number>
                  </div>
                </Styled.Information>
              </Styled.CompanyData>
            </div>
          ) : (
            <div>
              <div>
                <Styled.Heads>{Profiles?.about}</Styled.Heads>
                <Styled.Information>
                  <Styled.Airways>{firstName + " " + lastName}</Styled.Airways>
                  <Styled.NumberMail>
                    <Styled.Number>
                      <div className="heading">{Profiles?.mobile}</div>
                      <div className="data">
                        {singleProfileData?.mobile
                          ? singleProfileData?.mobile
                          : ""}
                      </div>
                    </Styled.Number>
                    <Styled.Mail>
                      <div className="heading">{Profiles?.email}</div>
                      <div className="data">
                        {singleProfileData?.email
                          ? singleProfileData?.email
                          : ""}
                      </div>
                    </Styled.Mail>
                  </Styled.NumberMail>
                </Styled.Information>
              </div>
              <Styled.CompanyData MarginTop="41px">
                <Styled.Heads>{Profiles?.details}</Styled.Heads>
                <Styled.Information>
                  <Styled.Airways>
                    {singleProfileData?.companyName
                      ? singleProfileData?.companyName
                      : ""}
                  </Styled.Airways>
                  <Styled.NumberMail>
                    <Styled.Number>
                      <div className="heading">{Profiles?.company}</div>
                      <div className="data">
                        {singleProfileData?.addressLine1
                          ? singleProfileData?.addressLine1
                          : ""}
                      </div>
                    </Styled.Number>
                    <Styled.Mail>
                      <div className="heading">{Profiles?.landlines}</div>
                      <div className="data">
                        {singleProfileData?.landingNo
                          ? singleProfileData?.landingNo
                          : ""}
                      </div>
                    </Styled.Mail>
                  </Styled.NumberMail>
                  <Styled.BreakLine lineMargin="19px 0px 3px 0px" />
                  <Styled.NumberData>
                    <Styled.Number>
                      <div className="heading">{Profiles?.regNo}</div>
                      <div className="data">
                        {singleProfileData?.companyRegNo
                          ? singleProfileData?.companyRegNo
                          : ""}
                      </div>
                    </Styled.Number>
                    <Styled.Mail>
                      <div className="heading">{Profiles?.nature}</div>
                      <div className="data">{Profiles?.Airline}</div>
                    </Styled.Mail>
                  </Styled.NumberData>
                </Styled.Information>
              </Styled.CompanyData>
              <Styled.CompanyData MarginTop="30px">
                <Styled.Heads>{Profiles?.emp}</Styled.Heads>
                <Styled.Information>
                  <div>
                    <Styled.Number>
                      <div className="data">{Profiles?.type}</div>
                    </Styled.Number>
                    <Styled.BreakLine lineMargin="17px 0px" />
                    <Styled.Number>
                      <div className="heading">{Profiles?.aviation}</div>
                      <div className="data">{Profiles?.yes}</div>
                    </Styled.Number>
                  </div>
                </Styled.Information>
              </Styled.CompanyData>
            </div>
          )}
        </Styled.ProfileData>
      </div>
    </>
  );
};

export default SingleProfile;
