import styled from "styled-components";
import { color } from "../../../theme/Color";
import { Modal } from "antd";

export const Container = styled.div`
  padding: 0px 39px;
`;
export const ItText = styled.div`
  color: ${color._16384E};
  text-align: justify;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const Date = styled.div`
  color: ${color._177a9c};
  text-align: justify;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: lowercase;
  outline: none;
  border: none;
  &::placeholder {
    color: ${color._177a9c};
    text-align: justify;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: lowercase;
  }
  &.fulldate {
    padding: 12px 0px;
  }
`;
export const ListText = styled.div`
  .rulesText {
    padding-bottom: 13px;
  }
  padding-top: 13px;
  .backgroundtext {
    color: ${color._16384E};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  color: ${color._16384E};
  text-align: justify;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const InputTextRoll = styled.input`
  border: none;
  border-bottom: 1.5px dotted ${color._16384E};
  text-transform: capitalize;
  width: 20%;
  outline: none;
  background: rgba(81, 147, 170, 0.05);
  color: ${color._177a9c};
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 0px 10px;
  &::placeholder {
    text-align: center;
  }
`;
export const InputTextInviteRoll = styled.span`
  border: none;
  border-bottom: 1.5px dotted ${color._16384E};
  width: 20%;
  outline: none;
  background: rgba(81, 147, 170, 0.05);
  color: ${color._177a9c};
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 0px 10px;
  text-transform: capitalize;
  &::placeholder {
    text-align: center;
  }
`;

export const InputText = styled.input`
  border: none;
  border-bottom: 1.5px dotted ${color._16384E};
  width: 30%;
  outline: none;
  background: rgba(81, 147, 170, 0.05);
  color: ${color._177a9c};
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 0px 10px;
  text-transform: capitalize;
  &::placeholder {
    text-align: center;
  }
`;
export const InviteInputText = styled.span`
  border: none;
  border-bottom: 1.5px dotted ${color._16384E};
  width: 30%;
  outline: none;
  background: rgba(81, 147, 170, 0.05);
  color: ${color._177a9c};
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 0px 10px;
  text-transform: capitalize;
  &::placeholder {
    text-align: center;
  }
`;
export const LearnerName = styled.div`
  width: 90%;
  .confirm {
    color: ${color._16384E};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 157.4%;
  }
  .nameborder {
    color: ${color._177a9c};
    text-align: justify;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 0px 10px;
  }
`;
export const BackgroundCheck = styled.div`
  .check {
    color: ${color._16384E};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 145.9%;
  }
  color: ${color._16384E};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 145.9%;
  padding-top: 7px;
`;
export const PleaseText = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  margin-bottom: 12px;
  .please {
    color: ${color._177a9c};

    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
  }
  .please::before {
    content: "*";
    color: ${color._F00E00};
    font-size: 15px;
    font-weight: 400;
  }
  .inputstyled {
    width: 17px;
    height: 17px;
  }
`;
export const BackgroundContainer = styled.div`
  background: rgba(81, 147, 170, 0.05);
`;
export const BackgroundContainer1 = styled.div`
  background: rgba(81, 147, 170, 0.05);
  padding: 25px 39px;
`;
export const BoxSize = styled.div`
  padding: 17px 39px;
  .airside {
    color: ${color._16384E};

    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .textContainer {
    color: ${color._16384E};
    text-align: justify;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 12px;
    padding-bottom: 5px;
  }
`;
export const MALPRACTICE = styled.div`
  padding-top: 21px;
  .malpracticeText {
    padding-top: 15px;
    color: ${color._16384E};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-align: justify;
    line-height: normal;
  }
  .malpractice {
    color: ${color._16384E};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;
export const AppropriateContainer = styled.div`
  padding-bottom: 21px;
  .appropriate {
    color: ${color._16384E};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 21px;
  }
  .purchasing {
    color: ${color._16384E};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 15px;
    text-align: justify;
  }
  .dftText {
    color: ${color._16384E};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 15px;
    text-align: justify;
  }
`;
export const BoxContainer = styled.div`
  display: flex;
`;
export const YourEmail = styled.div`
  flex: 0.3;
  display: flex;
  align-items: end;
  .company {
    padding-bottom: 8px;
    color: ${color._68828e};
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
  }
  .textContainer {
    color: ${color._177a9c};
    text-align: justify;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: lowercase;
  }
`;
export const CompanyRegNumber = styled.div`
  flex: 0.4;
  .company {
    padding-bottom: 8px;
    color: ${color._68828e};
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
  }
  .textContainer {
    color: ${color._177a9c};
    text-align: justify;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: lowercase;
    padding-bottom: 17px;
  }
  .textContainer1 {
    color: ${color._177a9c};
    text-align: justify;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: lowercase;
    padding-bottom: 65px;
  }
  .textContainer2 {
    color: ${color._177a9c};
    text-align: justify;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: lowercase;
  }
`;
export const CompanyName = styled.div`
  flex: 0.5;
  .nameContainer {
    display: flex;
    flex-direction: column;
  }
  .company {
    padding-bottom: 8px;
    color: ${color._68828e};
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
  }
  .companytext {
    color: ${color._177a9c};
    text-align: justify;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    padding-bottom: 16px;
  }
  .companytext1 {
    padding-bottom: 31px;
    color: ${color._177a9c};
    text-align: justify;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }
  .companytext2 {
    color: ${color._177a9c};
    text-align: justify;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }
`;
export const Error = styled.div`
  color: #ff0000;
`;
export const SignImage = styled.div`
  .sign {
    width: 200px;
  }
`;
export const LastContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 66px 39px;
  .add {
    color: ${color._177a9c};
    text-align: justify;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border: none;
    border-radius: 5px;
  }
  .date {
    color: ${color._68828e};
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
  }
  .fulldate {
    color: ${color._177a9c};
    text-align: justify;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: lowercase;
  }
  .crossicon {
    color: ${color._15374D};
    text-align: justify;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    gap: 5px;
    align-items: center;
    border-bottom: 1px solid ${color._000000};
    padding-right: 25px;
  }
`;
export const JobRole = styled.div`
  margin-top: 22px;
  .textContainer2 {
    color: ${color._177a9c};
    text-align: justify;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }
`;
export const Input = styled.input`
  width: 299px;
  height: 31px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 0.5px solid ${color._DADADA};
  outline: none;
  background: ${color._ffffff};
  text-transform: capitalize;
`;
export const SoleTrader = styled.div`
  margin-top: 5px;
  .trader {
    padding: 20px;
    color: ${color._16384e};
    font-size: 15px;
    font-style: italic;
    font-weight: 500;
    line-height: normal;
    height: 127px;
    flex-shrink: 0;
    background: rgba(242, 0, 0, 0.03);
  }
`;
export const Label = styled.label`
  color: ${color._68828e};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 95%;
  font-family: Open Sans;
  .star {
    color: ${color._f20000};
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
  }
`;

export const ModalContainer = styled(Modal)`
  & .ant-modal-content {
    padding: 10px 40px;
  }
  &.ant-modal {
    max-width: calc(100% - 32px);
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ModalBox = styled.div`
  height: 200px;
  flex-shrink: 0;
  border-radius: 2px;
  background: ${color._0f2a3c};
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
`;
export const Icon = styled.div`
  width: 38px;
  height: 38px;
  background: rgba(239, 243, 249, 0.38);
  border-radius: 28px;
  cursor: pointer;
  .closeIcon {
    height: 24px;
    width: 24px;
    color: rgba(109, 125, 139, 1);
    margin-top: 7px;
    margin-left: 7px;
  }
`;

export const ModalTitle = styled.div`
  color: ${color._16384e};
  font-family: Raleway;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 25px;
`;

export const SignUploadInputWrapper = styled.div`
  width: 620px;
  height: 200px;
  border: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .uploadImageText {
    border: 1px solid #f0f0f0;
    background: rgba(0, 0, 0, 0.08);
    padding: 5px 10px;
    cursor: pointer;
    font-weight: bold;
  }
`;

export const SignText = styled.div`
  width: 70%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`;
export const SignWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;
export const SignedImage = styled.div`
  margin-top: 5px;
  background-color: rgba(0, 0, 0, 0.02);
  width: 320px;
`;

export const TextInput = styled.input`
  width: 60%;
  font-size: 18px;
  border-radius: 5px;
  padding: 10px 15px;
  border: 2px solid #f0f0f0;
`;

export const ChildText = styled.div`
  margin-top: 5px;
  text-align: center;
  .imageError {
    color: red;
  }
`;
