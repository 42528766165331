import * as Styled from "./style";
import RatingModal from "./RatingModal";
import React, { useEffect } from "react";
import eng from "../../../assets/Svg/eng.svg";
import gsatimg from "../../../assets/Svg/gsatimg.svg";
import tv from "../../../assets/Svg/tv.svg";
import { course, rating } from "./data";
import { useSelector } from "react-redux";
import { Flex, Rate } from "antd";
import dayjs from "dayjs";

const TrainingAwareness = ({
  coursesDetails,
  isModalOpen,
  setIsModalOpen,
  getReviewList,
}) => {
  const reviewList = useSelector(
    (state) => state.reviewReducer?.reviewList?.data?.reviews
  );

  useEffect(() => {
    getReviewList();
  }, []);
  return (
    <>
      <Styled.Courses className="container">
        <div className="about">{course.about}</div>
        <Styled.Gsat>
          <div>
            <img src={gsatimg} alt="gsat" />
          </div>
          <div className="security">
            {/* <span className="general"> {course.general}</span> */}
            {coursesDetails?.course?.about}
          </div>
        </Styled.Gsat>
        <Styled.Rating>
          <div className="eng">
            <img src={tv} alt="tv" />
            <div>{coursesDetails?.course?.language}</div>
          </div>
          <div className="hrs">
            <img src={eng} alt="eng" />
            <div>{coursesDetails?.course?.duration}</div>
          </div>
          <div className="ratings">
            Rating
            <div>
              {rating?.map((item, index) => {
                return <img key={index} src={item?.rate} />;
              })}
            </div>
            <span className="rate">1.2k</span>
          </div>
        </Styled.Rating>
        <div className="asessVali">
          <div className="online">
            <span className="assess"> {course.assess}</span>
            {coursesDetails?.course?.assessment}
          </div>
          <div className="vali">
            {course.validate}
            <span>{coursesDetails?.course?.validity}</span>
          </div>
        </div>
        <Styled.Line />
        <Styled.Certify>
          <div className="cert">{course.certify}</div>
          <div className="grey">
            <div className="comp">{course.comp}</div>
          </div>
        </Styled.Certify>

        <Styled.Line />
        <Styled.Review>
          <Styled.Cart>
            <div className="cert">{course.review}</div>
            {/* <Styled.FilterRate>
              <div className="filterRate">{course.filterRate}</div>
              <div className="optionValue">
                <select id="selectOption">
                  <option value="option1">All ratings</option>
                </select>
              </div>
            </Styled.FilterRate> */}
          </Styled.Cart>
          <Styled.LeaveRating>
            <div className="rating">
              <div className="rateIndex">
                <div>
                  {rating?.map((item, index) => {
                    return <img key={index} src={item?.rate} />;
                  })}
                </div>
                <div className="rates5">
                  {course.rates5}
                  <span className="rates10">{course.ratings10}</span>
                </div>
              </div>
              <div className="btn">
                <RatingModal
                  name={course.cont}
                  coursesDetails={coursesDetails}
                  isModalOpen={isModalOpen}
                  setIsModalOpen={setIsModalOpen}
                />
              </div>
            </div>
            <Styled.Group>
              {reviewList?.map((item) => {
                const firstName = item.User?.firstName
                  ? item.User?.firstName
                  : "";
                const lastName = item.User?.lastName ? item.User?.lastName : "";
                const fullName =
                  firstName.charAt(0).toUpperCase() +
                  " " +
                  lastName.charAt(0).toUpperCase();
                const userName = firstName + " " + lastName;
                return (
                  <div className="jdPage">
                    <div className="jd">{fullName}</div>
                    <div className="pageJohn">
                      <div className="john">{userName}</div>
                      <Flex gap="middle" vertical className="stars">
                        <Rate disabled name="rating" value={item?.rating} />
                      </Flex>
                      <div className="page">{item?.comment}</div>
                      <div className="week">
                        {dayjs(item?.createdAt).format("DD-MM-YYYY")}
                      </div>
                    </div>
                  </div>
                );
              })}
            </Styled.Group>
          </Styled.LeaveRating>
        </Styled.Review>
      </Styled.Courses>
    </>
  );
};

export default TrainingAwareness;
