import styled from "styled-components";
import { Progress } from "antd";
import { color } from "../../../theme/Color";

export const MainComp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 537px;
  gap: 4%;
`;
export const ProgressComp = styled.div`
  flex: 0.5;
  text-align: center;
  position: relative;
  right: 2%;
`;
export const Congratulate = styled.div`
  flex: 0.5;
  .con {
    color: ${color._000000};
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .success {
    color: ${color._6d7d8b};
    margin-top: 30px;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .hesitate {
    color: ${color._6d7d8b};
    margin-top: 16px;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .xx {
    color: ${color._177a9c};
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .kind {
    color: ${color._6d7d8b};
    font-size: 15px;
    margin-top: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;
export const ProgressCircle = styled(Progress)`
  &.ant-progress.ant-progress-circle .ant-progress-text {
    color: ${color._009ed5};
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-size: 42px;
  }
`;
