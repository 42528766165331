export const NavData = [
  {
    id: 0,
    name: "Home",
    path: "/",
  },
  {
    id: 1,
    name: "About Us",
    path: "/about-us",
  },
  {
    id: 2,
    name: "Training",
    path: "/training-course",
    except:["Finance Account", "Operations"]
  },
  {
    id: 3,
    name: "Recruitment",
    path: "/recruitment",
  },
  {
    id: 4,
    name: "Vetting",
    path: "/vetting",
  },
  {
    id: 5,
    name: "News",
    children: [
      { id: 5, name: "Coronavirus – Meeting the Training Challenges Together" },
    ],

  },
  {
    id: 6,
    name: "Resources",
    path: "/resources",
  },
  {
    id: 7,
    name: "Contact",
    path: "/contact",
  },
];
