import React from "react";
import * as Styled from "./style";
import DeclarationLogo from "../../../assets/Svg/DeclarationLogo.svg";
import { AccountDashboardData, icons } from "./data";
import { useSelector } from "react-redux";

const DeclarationFormPdf = () => {
  const userDetail = useSelector((state) => state?.authReducer?.user);

  return (
    <div style={{ width: "70%" }}>
      <Styled.Header>
        <Styled.HeaderContent>
          <img src={DeclarationLogo} alt="icon" />
        </Styled.HeaderContent>
      </Styled.Header>
      <div style={{ width: "75%", margin: "0 auto" }}>
        <Styled.Heading>Background Check Declaration</Styled.Heading>
        <Styled.Container>
          <Styled.ItText>{AccountDashboardData.It}</Styled.ItText>
          <Styled.ListText>
            <div className="rulesText">
              {AccountDashboardData.IN}
              <span className="backgroundtext">
                {AccountDashboardData.Background}
              </span>
              {AccountDashboardData.shall}
            </div>
            <ol>
              <li>{AccountDashboardData.establish}</li>
              <li>{AccountDashboardData.cover}</li>
              <li>{AccountDashboardData.criminal}</li>
            </ol>
          </Styled.ListText>
        </Styled.Container>
        <Styled.BoxSize>
          <Styled.LearnerName>
            <span className="confirm">{AccountDashboardData.confirm}</span>
            <Styled.InputText type="text" name="name" required />
            <span className="confirm">{AccountDashboardData.isEmployed}</span>
            <Styled.InputTextRoll type="text" name="role" required />
            <span className="confirm">{AccountDashboardData.Successfully}</span>
          </Styled.LearnerName>
          <Styled.BackgroundCheck>
            <span className="check">{AccountDashboardData.BACKGROUND} </span>
            {AccountDashboardData.access}
          </Styled.BackgroundCheck>
          <Styled.PleaseText>
            <div className="please">{AccountDashboardData.Pleasetick}</div>
            <input
              type="checkbox"
              className="inputstyled"
              name="backgroundCeck"
              required
            />
          </Styled.PleaseText>
          <span className="airside">{AccountDashboardData.And}</span>
          <div className="textContainer">
            {AccountDashboardData.Has}
            <span className="airside">{AccountDashboardData.AIRSIDE}</span>
            {AccountDashboardData.assigned}
            <span className="airside">{AccountDashboardData.Security}</span>
            {AccountDashboardData.required}
          </div>
          <Styled.PleaseText>
            <div className="please">{AccountDashboardData.Pleasetick}</div>
            <input
              type="checkbox"
              className="inputstyled"
              name="cpsra"
              required
            />
          </Styled.PleaseText>
        </Styled.BoxSize>
        <Styled.Container>
          <Styled.MALPRACTICE>
            <div className="malpractice">
              {AccountDashboardData.MALPRACTICE}
            </div>
            <div className="malpracticeText">
              {AccountDashboardData.deliberate}
            </div>
          </Styled.MALPRACTICE>
          <Styled.AppropriateContainer>
            <div className="appropriate">
              {AccountDashboardData.APPROPRIATEAUTHORITYACTION}
            </div>
            <div className="dftText">
              {AccountDashboardData.The}
              <span className="appropriate">{AccountDashboardData.DfT}</span>
              {AccountDashboardData.willseek}
            </div>
            <div className="purchasing ">
              {AccountDashboardData.purchasing}{" "}
              <span className="appropriate">{AccountDashboardData.DfT}</span>{" "}
              {AccountDashboardData.result}{" "}
              <span className="appropriate">{AccountDashboardData.DfT}</span>
              {AccountDashboardData.contravention}{" "}
              <span className="appropriate">
                {AccountDashboardData.National}
              </span>
              {AccountDashboardData.associatedsyllabuses}
            </div>
          </Styled.AppropriateContainer>
        </Styled.Container>
        <Styled.BackgroundContainer>
          <Styled.BoxContainer>
            <Styled.CompanyName>
              <div className="nameContainer">
                <div className="company">
                  {AccountDashboardData.CompanyName}
                </div>
                <div className="companytext">
                  {userDetail?.companyName ? userDetail?.companyName : ""}
                </div>
              </div>
              <div className="nameContainer">
                <div className="company">
                  {AccountDashboardData.CompanyAddress}
                </div>
                <div className="companytext2">
                  {userDetail?.addressLine1 ? userDetail?.addressLine1 : ""}
                </div>
                <div className="companytext2">
                  {userDetail?.addressLine2 ? userDetail?.addressLine2 : ""}
                </div>
                <div className="companytext1">
                  {userDetail?.city ? userDetail?.city : ""},
                  {userDetail?.postcode ? userDetail?.postcode : ""}
                </div>
              </div>
            </Styled.CompanyName>
            <Styled.CompanyRegNumber>
              <div className="nameContainer">
                <div className="company">
                  {AccountDashboardData.CompanyRegNumber}
                </div>
                <div className="textContainer">
                  {userDetail?.companyRegNo ? userDetail?.companyRegNo : ""}
                </div>
              </div>
              <div className="nameContainer">
                <div className="company">
                  {AccountDashboardData.LandlineNumber}
                </div>
                <div className="textContainer1">
                  {userDetail?.landingNo ? userDetail?.landingNo : ""}
                </div>
              </div>
            </Styled.CompanyRegNumber>
          </Styled.BoxContainer>
        </Styled.BackgroundContainer>
        <Styled.DetailContainer>
          <Styled.MainContainer>
            <div className="nameContainer">
              <div className="company">Your Name</div>
              <Styled.InputField type="text" name="name" required />
            </div>
            <div className="nameContainer">
              <div className="company">Date</div>
              <Styled.InputField type="text" name="date" required />
            </div>
          </Styled.MainContainer>
          <Styled.MainContainer>
            <div className="nameContainer">
              <div className="company">Mobile Number</div>
              <Styled.InputField type="text" name="number" required />
            </div>
            <div className="nameContainer">
              <div className="company">Signature</div>
              <Styled.InputField type="text" name="sign" required />
            </div>
          </Styled.MainContainer>
          <Styled.MainContainer>
            <div className="nameContainer">
              <div className="company">Email</div>
              <Styled.InputField type="text" name="email" required />
            </div>
          </Styled.MainContainer>
        </Styled.DetailContainer>
      </div>
    </div>
  );
};

export default DeclarationFormPdf;
