import * as Styled from "./style";
import Button from "../Button/button";
import React, { useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import HttpApis from "../../../services/HttpApis";

const Signature = ({ signatureRef, handleSubmit }) => {
  return (
    <Styled.Signature>
      <div className="canvas">
        <SignatureCanvas
          penColor="black"
          canvasProps={{ width: 710, height: 200 }}
          ref={(eleRef) => (signatureRef.current = eleRef)}
          onEnd={handleSubmit}
          maxWidth={3}
          minWidth={2}
          dotSize={3}
        />
      </div>
      <Styled.ChildText>Sign Here </Styled.ChildText>
      {/* <div className="butn">
        {userDetail?.type === "Finance Account" ||
        userDetail?.type === "Operations" ? (
          <Button title={"Save"} onClick={handleSubmit} className="btn" />
        ) : null}
        <Button title={"Clear"} onClick={handleClear} className="btn" />
      </div> */}
    </Styled.Signature>
  );
};
export default Signature;
